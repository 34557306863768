import { Trans } from '@lingui/macro'
import Web3Status from 'components/Web3Status'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { MonoLogo } from 'nft/components/icons'
import { ReactNode, useEffect, useState } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ExternalLink } from 'theme'

import { ChainSelector } from './ChainSelector'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: 20px 12px;
  width: 80%;
  margin: 0px auto;
  height: 80px;
  z-index: 2;
  color: #efefe4 !important;
  @media screen and (max-width: 1600px) {
    width: 100%;
    padding: 20px;
  }
`

const SubMenuListDesktop = styled.div<{ bg: string }>`
  color: #efefe4;
  font-size: 16px !important;
  font-family: Righteous;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 125%;
  word-wrap: break-word;

  width: 248px;
  position: absolute;
  top: 50px;
  right: 0;
  display: grid;
  gap: 16px;
  z-index: 9999;
  background-color: #111015;
  clip-path: polygon(10% 0, 100% 0, 100% 85%, calc(100% - 10%) 100%, 0 100%, 0 15%);
  padding: 25px;
  opacity: 1;
  display: none;

  &:hover {
    display: grid;
  }
  @media screen and (max-width: 1200px) {
    padding: 20px;
    gap: 10px;
  }
`
const SubMenuListMobile = styled.div`
  color: #efefe4;
  font-size: 18px;
  font-family: Righteous;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 18px;
  word-wrap: break-word;
  background-size: cover;
  display: grid;
  gap: 16px;
  padding: 20px;
  padding-bottom: 0px;
  display: none;
`

const TransColored = styled.span<{ hoverOpacity?: number }>`
  color: #efefe4;
  font-size: 18px;
  font-family: Righteous;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 125%;
  word-wrap: break-word;
  display: flex;
  gap: 4px;
  opacity: 0.6;
  &:hover {
    opacity: ${({ hoverOpacity }) => hoverOpacity ?? 1};
  }

  @media screen and (max-width: 1600px) {
    font-size: 16px;
    align-items: center;
    img{
      width: 16px;
      height: 18px;
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 0 !important;
  }
  @media screen and (max-width: 1200px) {
    font-size: 14px;
    padding: 0 !important;
    img{
      width: 14px;
      height: 16px;
    }
  }
  @media screen and (max-width: 1150px) {
    font-size: 12px;
    img{
      width: 12px;
      height: 14px;
    }
  }
`

interface MenuItemProps {
  href?: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
  isDisable?: boolean
  onCloseAllSubmenu?: Function
}

const MenuItem = ({ href, dataTestId, id, isActive, children, isDisable, onCloseAllSubmenu }: MenuItemProps) => {
  const closeAllSubmenu = onCloseAllSubmenu ? onCloseAllSubmenu : () => { }
  return isDisable ? (
    <a
      onClick={() => closeAllSubmenu()}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none', position: 'relative', cursor: 'pointer', padding: '6px 10px' }}
      data-testid={dataTestId}
    >
      {children}
    </a>
  ) : (
    <NavLink
      to={href ?? '#'}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none', position: 'relative', padding: '6px 10px' }}
      data-testid={dataTestId}
      onClick={() => closeAllSubmenu()}
    >
      {children}
    </NavLink>
  )
}

const PageTabs = () => {
  const { pathname } = useLocation()
  // const { chainId: connectedChainId } = useWeb3React()
  // const chainName = chainIdToBackendName(connectedChainId)

  const isPoolActive =
    pathname.startsWith('/pool') ||
    pathname.startsWith('/add') ||
    pathname.startsWith('/remove') ||
    pathname.startsWith('/increase') ||
    pathname.startsWith('/find')

  // const isNftPage = useIsNftPage()

  let timeoutCloseSubmenu: NodeJS.Timeout
  const onHoverShowSubMenu = (e: any) => {
    if (timeoutCloseSubmenu) clearTimeout(timeoutCloseSubmenu)
    document.querySelectorAll('.submenu-desktop').forEach((el: any) => {
      closeSubmenu(el.parentElement)
    })
    let parentElement
    const currentTag = e.target
    if (currentTag.tagName == 'SPAN') parentElement = currentTag.parentElement
    // it's img
    else parentElement = currentTag.parentElement.parentElement

    parentElement.classList.add('isOpenSubmenu')
    parentElement.querySelector('img').style.rotate = '180deg'
    parentElement.querySelector('.submenu-desktop').style.display = 'grid'
  }

  const onCloseSubmenu = (e: any) => {
    let parentElement: any
    const currentTag = e.target
    if (currentTag.tagName == 'SPAN') parentElement = currentTag.parentElement
    // it's img
    else parentElement = currentTag.parentElement.parentElement
    if (parentElement.querySelector('.submenu-desktop')) {
      // for submenu
      parentElement.classList.remove('isOpenSubmenu')
      if (timeoutCloseSubmenu) clearTimeout(timeoutCloseSubmenu)
      timeoutCloseSubmenu = setTimeout(() => closeSubmenu(parentElement), 140)
    }
  }

  const closeSubmenu = (el: any, force = false) => {
    const submMenuTag = el.querySelector('.submenu-desktop')
    if (!force) if (submMenuTag.classList.contains('is-hovering') || el.classList.contains('isOpenSubmenu')) return

    el.querySelector('img').style.rotate = '0deg'
    submMenuTag.style.display = 'none'
  }

  const onMouseEnterSubmenu = (e: any) => {
    const currentTag = e.target

    if (currentTag.classList.contains('submenu-desktop')) currentTag.classList.add('is-hovering')
  }
  const onMouseLeaveSubmenu = (e: any) => {
    const currentTag = e.target

    if (currentTag.classList.contains('submenu-desktop')) {
      currentTag.classList.remove('is-hovering')
      const parentElement: any = currentTag.parentElement
      if (timeoutCloseSubmenu) clearTimeout(timeoutCloseSubmenu)
      timeoutCloseSubmenu = setTimeout(() => closeSubmenu(parentElement), 140)
    }
  }
  const onHoverNoSubmenuEl = () =>
    document.querySelectorAll('.submenu-desktop').forEach((el: any) => closeSubmenu(el.parentElement, true))

  useEffect(() => {
    onHoverNoSubmenuEl()
  }, [pathname])
  useEffect(() => {
    document.querySelector('.site-body')?.addEventListener('mouseenter', onHoverNoSubmenuEl)
  }, [])
  return (
    <>
      <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <TransColored hoverOpacity={0.6} onMouseEnter={onHoverShowSubMenu} onMouseLeave={onCloseSubmenu}>
          <Trans>TRADE</Trans>
          <img
            src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/arrow-down.png"
            alt="monoswap"
          />
        </TransColored>

        <SubMenuListDesktop
          onMouseEnter={onMouseEnterSubmenu}
          onMouseLeave={onMouseLeaveSubmenu}
          className="submenu-desktop"
          bg="url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/submenu-trade-bg.png')"
        >
          <NavLink to="/swap" style={{ textDecoration: 'none' }}>
            <TransColored>
              <Trans>SWAP</Trans>
            </TransColored>
          </NavLink>
          <NavLink to="/add" style={{ textDecoration: 'none' }}>
            <TransColored>
              <Trans>LIQUIDITY</Trans>
            </TransColored>
          </NavLink>
        </SubMenuListDesktop>
      </MenuItem>
      <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <TransColored hoverOpacity={0.6} onMouseEnter={onHoverShowSubMenu} onMouseLeave={onCloseSubmenu}>
          <Trans>EARN</Trans>
          <img
            src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/arrow-down.png"
            alt="monoswap"
          />
        </TransColored>
        <SubMenuListDesktop
          onMouseEnter={onMouseEnterSubmenu}
          onMouseLeave={onMouseLeaveSubmenu}
          className="submenu-desktop"
          bg="url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/submenu-earn-bg.png')"
        >
          <NavLink to="/pool" style={{ textDecoration: 'none' }}>
            <TransColored>
              <Trans>POSITIONS</Trans>
            </TransColored>
          </NavLink>

          <NavLink to="pools-list" style={{ textDecoration: 'none' }}>
            <TransColored>
              <Trans>ALL POOLS</Trans>
            </TransColored>
          </NavLink>
        </SubMenuListDesktop>
      </MenuItem>

      {/* <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <NavLink to="/xp" style={{ textDecoration: 'none' }}>
          <TransColored onMouseEnter={onHoverNoSubmenuEl} style={{ gap: '5px', padding: '0 10px' }}>
            <Trans>XP</Trans>
            <img
              style={{ width: '18px' }}
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/farm-tree-linear-icon.png"
            />
          </TransColored>
        </NavLink>
      </MenuItem> */}
      <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <NavLink to="/monochest" style={{ textDecoration: 'none' }}>
          <TransColored onMouseEnter={onHoverNoSubmenuEl} >
            <Trans>Chest</Trans>
          </TransColored>
        </NavLink>
      </MenuItem>
      <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <NavLink to="/lottery" style={{ textDecoration: 'none' }}>
          <TransColored onMouseEnter={onHoverNoSubmenuEl} >
            <Trans>Lottery</Trans>
          </TransColored>
        </NavLink>
      </MenuItem>

      <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <NavLink to="/launchpad" style={{ textDecoration: 'none' }}>
          <TransColored onMouseEnter={onHoverNoSubmenuEl} style={{ gap: '5px' }}>
            <Trans>LAUNCHPAD</Trans>
            <span>🚀</span>
          </TransColored>
        </NavLink>
      </MenuItem>

      <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <NavLink to="/monopass" style={{ textDecoration: 'none' }}>
          <TransColored onMouseEnter={onHoverNoSubmenuEl} >
            <Trans>PASS</Trans>
          </TransColored>
        </NavLink>
      </MenuItem>
      {/*  

      <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <TransColored style={{ textTransform: 'none' }} hoverOpacity={0.6} onMouseEnter={onHoverShowSubMenu} onMouseLeave={onCloseSubmenu}>
          <Trans>xMONO</Trans>
          <img
            src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/arrow-down.png"
            alt="monoswap"
          />
        </TransColored>
        <SubMenuListDesktop
          onMouseEnter={onMouseEnterSubmenu}
          onMouseLeave={onMouseLeaveSubmenu}
          className="submenu-desktop"
          bg="url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/submenu-earn-bg.png')"
        >
          <NavLink to="/xmono" style={{ textDecoration: 'none', textTransform: 'none' }}>
            <TransColored style={{ textTransform: 'none' }}>
              <Trans>xMONO</Trans>
            </TransColored>
          </NavLink>

          <NavLink to="/dividend" style={{ textDecoration: 'none' }}>
            <TransColored>
              <Trans>Dividend</Trans>
            </TransColored>
          </NavLink>
        </SubMenuListDesktop>
      </MenuItem> */}

      {/*<MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>*/}
      {/*  <TransColored style={{ color: '#efefe480' }} onMouseEnter={onHoverNoSubmenuEl} onMouseLeave={onCloseSubmenu}>*/}
      {/*    <Trans>AIRDROP</Trans>*/}
      {/*  </TransColored>*/}
      {/*</MenuItem>*/}

      {/* <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <NavLink to="/musd" style={{ textDecoration: 'none' }}>
          <TransColored onMouseEnter={onHoverNoSubmenuEl}>
            <Trans>MUSD</Trans>
          </TransColored>
        </NavLink>
      </MenuItem> */}

      {/* <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <TransColored style={{ color: '#efefe480' }} onMouseEnter={onHoverNoSubmenuEl}>
          <Trans>NFT</Trans>
        </TransColored>
      </MenuItem> */}



      <MenuItem isDisable={true} onCloseAllSubmenu={onHoverNoSubmenuEl}>
        <TransColored
          hoverOpacity={0.6}
          style={{ color: '#efefe480' }}
          onMouseEnter={onHoverShowSubMenu}
          onMouseLeave={onCloseSubmenu}
        >
          <Trans>MORE</Trans>
          <img
            src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/arrow-down.png"
            alt="monoswap"
          />
        </TransColored>
        <SubMenuListDesktop
          onMouseEnter={onMouseEnterSubmenu}
          onMouseLeave={onMouseLeaveSubmenu}
          className="submenu-desktop"
          bg="url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/submenu-more-bg.png')"
        >
          <NavLink to="/bridge" style={{ textDecoration: 'none' }}>
            <TransColored>
              <Trans>BRIDGE</Trans>
            </TransColored>
          </NavLink>

          <a href="https://docs.monoswap.io" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
            <TransColored>
              <Trans>DOCS</Trans>
            </TransColored>
          </a>
        </SubMenuListDesktop>
      </MenuItem>
      <TransColored style={{ minWidth: '150px', cursor: 'pointer' }} onMouseEnter={onHoverNoSubmenuEl} onClick={() => window.location.href = 'https://old.monoswap.io/'}>
        <Trans>V1 MONOSWAP</Trans>
      </TransColored>
    </>
  )
}

const MobileBottomBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  background-color: black;
  z-index: 100;
  padding-left: 20px;
  padding-top: 20px;
  overflow: auto;
  border-right: 1px solid #7f7f7f6e;
`

const MobileMenuWrapper = ({ onClose }: { onClose: Function }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onClickShowSubMenu = (e: any) => {
    e.stopPropagation()
    let parentElement
    const currentTag = e.target
    if (currentTag.tagName == 'SPAN') parentElement = currentTag.parentElement
    // it's img
    else parentElement = currentTag.parentElement.parentElement

    if (parentElement.classList.contains('isOpenSubmenu')) {
      // close submenu
      parentElement.classList.remove('isOpenSubmenu')
      parentElement.querySelector('img').style.rotate = '0deg'
      parentElement.querySelector('.submenu-mobile').style.display = 'none'
    } else {
      // open submenu
      parentElement.classList.add('isOpenSubmenu')
      parentElement.querySelector('img').style.rotate = '180deg'
      parentElement.querySelector('.submenu-mobile').style.display = 'grid'
    }
  }
  return (
    <>
      <MobileBottomBar>
        <Row display="flex" justifyContent="space-between" alignItems="center" paddingRight="20">
          <Box width="16" height="32" paddingLeft="12">
            <MonoLogo
              width="16"
              height="32"
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/mobile-logo.png"
            />
          </Box>
          <img
            onClick={() => onClose()}
            src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/close-icon.png"
            alt="monoswap"
          />
        </Row>

        <Row marginTop="40" gap={{ xl: '0', xxl: '8' }} display="grid">
          <MenuItem href="#" data-show={false}>
            <TransColored onClick={onClickShowSubMenu}>
              <Trans>TRADE</Trans>
              <img
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/arrow-down.png"
                alt="monoswap"
              />
            </TransColored>

            <SubMenuListMobile className="submenu-mobile">
              <NavLink to="/swap" style={{ textDecoration: 'none' }}>
                <TransColored>
                  <Trans>SWAP</Trans>
                </TransColored>
              </NavLink>
              <NavLink to="/add" style={{ textDecoration: 'none' }}>
                <TransColored>
                  <Trans>LIQUIDITY</Trans>
                </TransColored>
              </NavLink>
            </SubMenuListMobile>
          </MenuItem>
          <MenuItem href="#" data-show={false}>
            <TransColored onClick={onClickShowSubMenu}>
              <Trans>EARN</Trans>
              <img
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/arrow-down.png"
                alt="monoswap"
              />
            </TransColored>
            <SubMenuListMobile className="submenu-mobile">
              <NavLink to="/pool" style={{ textDecoration: 'none' }}>
                <TransColored>
                  <Trans>POSITIONS</Trans>
                </TransColored>
              </NavLink>
              <NavLink to="/pools-list" style={{ textDecoration: 'none' }}>
                <TransColored>
                  <Trans>ALL POOLS</Trans>
                </TransColored>
              </NavLink>
            </SubMenuListMobile>
          </MenuItem>
          {/* <MenuItem href="/xp">
            <TransColored style={{ gap: '5px' }}>
              <Trans>XP</Trans>
              <img
                style={{ width: '18px' }}
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/farm-tree-linear-icon.png"
                alt="monoswap"
              />
            </TransColored>
          </MenuItem> */}
          <MenuItem href="/monochest">
            <TransColored style={{ gap: '5px' }}>
              <Trans>Chest</Trans>
            </TransColored>
          </MenuItem>
          <MenuItem href="/lottery">
            <TransColored style={{ gap: '5px' }}>
              <Trans>Lottery</Trans>
            </TransColored>
          </MenuItem>
          <MenuItem href="/launchpad">
            <TransColored style={{ gap: '5px' }}>
              <Trans>LAUNCHPAD</Trans>
              <span>🚀</span>
            </TransColored>
          </MenuItem>
          <MenuItem href="/monopass">
            <TransColored style={{ gap: '5px' }}>
              <Trans>PASS</Trans>
            </TransColored>
          </MenuItem>
          {/*  

          <MenuItem href="#" data-show={false}>
            <TransColored style={{ textTransform: 'none' }} onClick={onClickShowSubMenu}>
              <Trans>xMONO</Trans>
              <img
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/arrow-down.png"
                alt="monoswap"
              />
            </TransColored>
            <SubMenuListMobile className="submenu-mobile">
              <NavLink to="/xmono" style={{ textTransform: 'none', textDecoration: 'none' }}>
                <TransColored style={{ textTransform: 'none' }}>
                  <Trans>xMONO</Trans>
                </TransColored>
              </NavLink>
              <NavLink to="/dividend" style={{ textDecoration: 'none' }}>
                <TransColored>
                  <Trans>Dividend</Trans>
                </TransColored>
              </NavLink>
            </SubMenuListMobile>
          </MenuItem> */}

          {/* <MenuItem href="/musd">
            <TransColored>
              <Trans>MUSD</Trans>
            </TransColored>
          </MenuItem> */}

          <MenuItem href="#" data-show={false}>
            <TransColored onClick={onClickShowSubMenu}>
              <Trans>MORE</Trans>
              <img
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/arrow-down.png"
                alt="monoswap"
              />
            </TransColored>
            <SubMenuListMobile className="submenu-mobile">
              <NavLink to="/bridge" style={{ textDecoration: 'none' }}>
                <TransColored>
                  <Trans>BRIDGE</Trans>
                </TransColored>
              </NavLink>
              <TransColored onClick={() => window.open('https://docs.monoswap.io', '_blank')}>
                <Trans>DOCS</Trans>
              </TransColored>
            </SubMenuListMobile>
          </MenuItem>

          <MenuItem href="#">
            <TransColored onClick={() => window.location.replace(`https://old.monoswap.io`)}>
              <Trans>V1 MONOSWAP</Trans>
            </TransColored>
          </MenuItem>
        </Row>
      </MobileBottomBar>
      <div
        onClick={() => onClose()}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: '#06060652',
          zIndex: 99,
        }}
      ></div>
    </>
  )
}
const LogoBox = styled.div`
  scale: 1;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    scale: 0.7;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`
const LogoBoxMobile = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`

const Navbar = () => {
  const isNftPage = useIsNftPage()
  const navigate = useNavigate()
  const location = useLocation()
  const [isShowMobileMenu, setIsShowMobileMenu] = useState<boolean>(false)

  useEffect(() => {
    setIsShowMobileMenu(false)
  }, [location])
  return (
    <>
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap" alignItems="center">
          <Box className={styles.leftSideContainer} justifyContent={{ sm: 'space-between', xl: 'flex-start' }}>
            <Row className={styles.logoContainer} gap="12" display="flex" justifyContent="center" alignItems="center">
              <Box
                onClick={() => setIsShowMobileMenu(true)}
                width="28"
                position="relative"
                display={{ lg: 'block', xl: 'none' }}
              >
                <svg
                  style={{ width: '100%', height: '100%' }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </Box>
              <LogoBox onClick={() => navigate('/home')}>
                <MonoLogo width="85" height="48" />
              </LogoBox>
              <LogoBoxMobile onClick={() => navigate('/home')}>
                <MonoLogo
                  width="16"
                  height="32"
                  src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/mobile-logo.png"
                />
              </LogoBoxMobile>
            </Row>
            <Row marginLeft={{ lg: '0', xxl: '0' }} gap={{ xl: '0', xxl: '8' }} display={{ sm: 'none', xl: 'flex' }}>
              <PageTabs />
            </Row>
          </Box>
          <Row display="flex" gap="8">
            <ChainSelector />
            <Web3Status />
          </Row>
        </Box>
        {isShowMobileMenu && <MobileMenuWrapper onClose={() => setIsShowMobileMenu(false)} />}
      </Nav>
    </>
  )
}

export default Navbar
